import ApiService from "@/common/api_service";
import { cloneData } from "@/common/cloneData";
import { generateFormData } from "@/common/generate_form_data";
import router from "@/router";
import { AxiosResponse } from "axios";
import { defineStore } from "pinia";
import { CustomerStoreStateInterface } from "../interfaces";
import {
    CustomerDataInterface,
    CustomerListItemInterface,
    CustomerDataEditInterface,
} from "./interfaces";

export const useCustomerStore = defineStore({
    id: "customer",
    state: (): CustomerStoreStateInterface => ({
        params: {
            search: "",
            page: 1,
            sort: "name",
        },
        customerList: [],
        editCustomer: { has_norm_manager: false },
        customer: { id: -1, has_norm_manager: false },
        customerPaginator: { page: 1, endOfList: false },
        isFetchingCustomers: false,
    }),
    getters: {},
    actions: {
        /**
         * FETCH
         */
        async fetchCustomers(): Promise<void> {
            this.params.page = this.customerPaginator.page;
            const response = await ApiService.get({
                resource: "customers",
                params: this.params,
            });

            if (response?.status == 200) {
                const responseData: CustomerListItemInterface[] =
                    response?.data.data;
                if (!response?.data.links.next) {
                    this.customerPaginator.endOfList = true;
                }
                if (this.customerPaginator.page == 1) {
                    this.customerList = [];
                }
                this.customerList = this.customerList.concat(responseData);
            }
        },
        async fetchCustomerData(
            customerId: number | string
        ): Promise<AxiosResponse | null> {
            const response = await ApiService.get({
                resource: "customers/" + customerId,
            });
            const responseData: CustomerDataInterface = response?.data.data;
            if (responseData) {
                this.editCustomer = cloneData(responseData);
                this.customer = cloneData(responseData);
            }
            return response;
        },

        /**
         * CREATE
         */
        async createCustomer(): Promise<boolean> {
            const finalCustomer = cloneData(this.editCustomer);
            finalCustomer.has_norm_manager =
                +this.editCustomer.has_norm_manager;
            const fd = generateFormData(finalCustomer, false);
            const response = await ApiService.post({
                resource: "customers",
                params: fd,
            });
            if (response?.status == 201) {
                const responseData: CustomerListItemInterface =
                    response?.data.data;
                if (
                    responseData &&
                    responseData.name
                        ?.toLowerCase()
                        .includes(this.params.search.toLowerCase())
                ) {
                    this.customerList.unshift(responseData);
                }

                router.push({
                    name: "CustomerAdministrationDetails",
                    params: { id: responseData.id },
                });
                return true;
            }

            return false;
        },
        /**
         * UPDATE
         */
        async updateCustomer(): Promise<boolean> {
            const finalCustomer = cloneData(this.editCustomer);
            finalCustomer.has_norm_manager =
                +this.editCustomer.has_norm_manager;

            const fd = generateFormData(finalCustomer, true);

            const response = await ApiService.post({
                resource: "customers/" + finalCustomer.id,
                params: fd,
            });

            if (response?.status == 201 || response?.status == 200) {
                const responseData: CustomerDataInterface = response?.data.data;
                if (responseData) {
                    const customerIndex = this.customerList.findIndex(
                        (element) => element.id == this.editCustomer.id
                    );
                    this.customerList[customerIndex] = cloneData(responseData);
                    this.customer = cloneData(this.editCustomer);
                }
                return true;
            }
            return false;
        },

        /**
         * CLONE
         */
        async cloneCustomer(
            customer: CustomerDataEditInterface
        ): Promise<boolean> {
            const response = await ApiService.post({
                resource: "customers/" + customer.id + "/copy",
                params: customer,
            });
            if (response?.status == 201) {
                const responseData: CustomerListItemInterface =
                    response?.data.data;
                if (
                    responseData &&
                    responseData.name
                        ?.toLowerCase()
                        .includes(this.params.search.toLowerCase())
                ) {
                    this.customerList.unshift(responseData);
                }

                router.push({
                    name: "CustomerAdministrationDetails",
                    params: { id: responseData.id },
                });
                return true;
            }

            return false;
        },

        async resendInvite(
            customer: CustomerListItemInterface
        ): Promise<boolean> {
            const response = await ApiService.post({
                resource: "customers/" + customer.id + "/invitation",
            });

            if (response?.status == 200) {
                return true;
            }
            return false;
        },
        /**
         * DELETE
         */

        async deleteCustomer(): Promise<boolean> {
            const response = await ApiService.delete({
                resource: "customers/" + this.editCustomer.id,
            });
            if (response?.status == 200) {
                const customerIndex = this.customerList.findIndex(
                    (element) => element.id == this.editCustomer.id
                );
                if (customerIndex >= 0)
                    this.customerList.splice(customerIndex, 1);

                router.push({
                    name: "CustomerAdministration",
                });
                return true;
            }

            return false;
        },
    },
});
