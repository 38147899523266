
import { defineComponent, ref } from "vue";
import InputSearch from "@/components/inputs/inputSearch/InputSearch.vue";
import IconClose from "@/components/icons/IconCloseSideoverlay.vue";
import IconContainer from "@/components/helpers/iconContainer/IconContainer.vue";
import CustomerOverview from "./CustomerOverviewContainer.vue";
import { storeToRefs } from "pinia";
import { useCustomerNormStore } from "@/store/customerNormRelation";

export default defineComponent({
    name: "SideContainer",
    components: { IconClose, IconContainer, InputSearch, CustomerOverview },
    props: {
        isContainerOpen: {
            type: Boolean,
            required: true,
        },
    },
    setup() {
        const customerFilter = ref("");
        const { customerParams } = storeToRefs(useCustomerNormStore());
        return {
            customerFilter,
            customerParams,
        };
    },
});
