import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full h-full flex flex-col overflow-hidden" }
const _hoisted_2 = {
  id: "listHeader",
  class: "md:flex flex-shrink-0 hidden gap-3 mt-6 mb-2 pr-2.5 w-full overflow-hidden"
}
const _hoisted_3 = ["title", "onClick"]
const _hoisted_4 = {
  key: 0,
  class: "flex justify-center items-center w-full h-full text-grey-darker"
}
const _hoisted_5 = {
  id: "customer_norm_list",
  class: "overflow-y-auto flex flex-col pr-1 gap-1 h-full mt-3 md:mt-0"
}
const _hoisted_6 = {
  key: 1,
  class: "w-full flex justify-center h-8 bg-white"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_arrow = _resolveComponent("icon-arrow")!
  const _component_list_item = _resolveComponent("list-item")!
  const _component_list_item_mobile = _resolveComponent("list-item-mobile")!
  const _component_loading_animation = _resolveComponent("loading-animation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setSort('norm_state', 0))),
        class: "ml-1 flex-shrink-0 flex w-6 cursor-pointer"
      }, [
        _cache[7] || (_cache[7] = _createElementVNode("p", { class: "text-sm text-grey-darker" }, "~", -1)),
        _createVNode(_component_icon_arrow, {
          class: _normalizeClass([`${
                        _ctx.params.sort == 'norm_state' ||
                        _ctx.params.sort == '-norm_state'
                            ? 'flex'
                            : 'hidden'
                    } ${
                        _ctx.params.sort == 'norm_state'
                            ? 'transform rotate-180'
                            : ''
                    }`, "transform scale-75 transition-all flex-shrink-0"]),
          color: 'grey-dark'
        }, null, 8, ["class"])
      ]),
      _createElementVNode("div", {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setSort('index', 0))),
        class: "ml-2 flex-shrink-0 flex w-6 cursor-pointer"
      }, [
        _cache[8] || (_cache[8] = _createElementVNode("p", { class: "text-sm text-grey-darker" }, "#", -1)),
        _createVNode(_component_icon_arrow, {
          class: _normalizeClass([`${
                        _ctx.params.sort == 'index' || _ctx.params.sort == '-index'
                            ? 'flex'
                            : 'hidden'
                    } ${_ctx.params.sort == 'index' ? 'transform rotate-180' : ''}`, "transform scale-75 transition-all flex-shrink-0"]),
          color: 'grey-dark'
        }, null, 8, ["class"])
      ]),
      _createElementVNode("div", {
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setSort('status_short_name', 0))),
        class: "flex-shrink-0 flex cursor-pointer w-14"
      }, [
        _cache[9] || (_cache[9] = _createElementVNode("p", { class: "text-sm text-grey-darker" }, "Status", -1)),
        _createVNode(_component_icon_arrow, {
          class: _normalizeClass([`${
                        _ctx.params.sort == 'status_short_name' ||
                        _ctx.params.sort == '-status_short_name'
                            ? 'flex flex-shrink-0'
                            : 'hidden'
                    } ${
                        _ctx.params.sort == 'status_short_name'
                            ? 'transform rotate-180'
                            : ''
                    }`, "transform scale-75 transition-all flex-shrink-0"]),
          color: 'grey-dark'
        }, null, 8, ["class"])
      ]),
      _createElementVNode("div", {
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setSort('type_short_name', 0))),
        class: "flex-shrink-0 flex cursor-pointer",
        style: {"width":"100px"}
      }, [
        _cache[10] || (_cache[10] = _createElementVNode("p", { class: "text-sm text-grey-darker" }, "Art", -1)),
        _createVNode(_component_icon_arrow, {
          class: _normalizeClass([`${
                        _ctx.params.sort == 'type_short_name' ||
                        _ctx.params.sort == '-type_short_name'
                            ? 'flex'
                            : 'hidden'
                    } ${
                        _ctx.params.sort == 'type_short_name'
                            ? 'transform rotate-180'
                            : ''
                    }`, "transform scale-75 transition-all flex-shrink-0"]),
          color: 'grey-dark'
        }, null, 8, ["class"])
      ]),
      _createElementVNode("div", {
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setSort('number', 0))),
        class: _normalizeClass(["flex-shrink-0 flex cursor-pointer -ml-0.5 mr-1", `${_ctx.chosenCustomers.length < 4 ? 'w-44' : 'w-28'}`])
      }, [
        _cache[11] || (_cache[11] = _createElementVNode("p", { class: "text-sm w-full text-left text-grey-darker" }, "Nummer", -1)),
        _createVNode(_component_icon_arrow, {
          class: _normalizeClass([`${
                        _ctx.params.sort == 'number' || _ctx.params.sort == '-number'
                            ? 'flex'
                            : 'hidden'
                    } ${_ctx.params.sort == 'number' ? 'transform rotate-180' : ''}`, "transform scale-75 transition-all flex-shrink-0"]),
          color: 'grey-dark'
        }, null, 8, ["class"])
      ], 2),
      _createElementVNode("div", {
        class: "w-full grid gap-3 pr-1 overflow-hidden",
        style: _normalizeStyle(_ctx.customerHeaderColCount)
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chosenCustomers, (customer) => {
          return (_openBlock(), _createElementBlock("div", {
            key: customer.id,
            class: "flex justify-start items-center overflow-hidden w-full"
          }, [
            _createElementVNode("p", {
              class: "text-sm text-grey-darker truncate cursor-pointer text-left max-w-full",
              title: customer.name,
              onClick: ($event: any) => (_ctx.setSort('-customer_exists', customer.id))
            }, _toDisplayString(customer.name), 9, _hoisted_3),
            _createVNode(_component_icon_arrow, {
              class: _normalizeClass([`${
                            (_ctx.params.sort == '-customer_exists' ||
                                _ctx.params.sort == 'customer_exists') &&
                            _ctx.params.customer_id == customer.id
                                ? 'flex flex-shrink-0 ml-1'
                                : 'opacity-0'
                        } ${
                            _ctx.params.sort == 'customer_exists'
                                ? 'transform rotate-180'
                                : ''
                        } `, "transform scale-75 transition-transform flex-shrink-0"]),
              color: 'grey-dark'
            }, null, 8, ["class"])
          ]))
        }), 128))
      ], 4)
    ]),
    (!_ctx.isLoading && _ctx.normList.length == 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t("no_search_result")), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.normList, (norm, index) => {
        return (_openBlock(), _createBlock(_component_list_item, {
          class: "hidden md:flex",
          key: norm.id,
          item: norm,
          index: index,
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('openDetails')))
        }, null, 8, ["item", "index"]))
      }), 128)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.normList, (norm, index) => {
        return (_openBlock(), _createBlock(_component_list_item_mobile, {
          class: "flex md:hidden",
          key: norm.id,
          item: norm,
          index: index,
          onOpenDetails: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('openDetails')))
        }, null, 8, ["item", "index"]))
      }), 128))
    ]),
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_loading_animation, {
            id: 'loadingAnimation',
            name: 'loadingAnimation',
            class: "mt-2",
            animationStyle: {
                    radius: '12',
                    animationColor: 'grey',
                }
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}