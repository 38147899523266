import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-5 h-full overflow-hidden flex flex-col" }
const _hoisted_2 = {
  key: 0,
  class: "pb-5 mb-5 border-b-2 border-grey-lightest flex-shrink-0"
}
const _hoisted_3 = { class: "mb-5" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "text-primary group-hover:text-red ml-3 truncate w-64 md:w-full" }
const _hoisted_6 = { class: "mb-5 flex-shrink-0" }
const _hoisted_7 = { class: "text-sm transition-all text-grey" }
const _hoisted_8 = {
  key: 1,
  class: "flex justify-center items-center w-full h-full text-grey-darker"
}
const _hoisted_9 = {
  id: "available_customer_list",
  class: "h-full overflow-y-auto pr-1"
}
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "text-grey-darkest ml-3 truncate w-64 md:w-full" }
const _hoisted_12 = {
  key: 2,
  class: "w-full flex justify-center items-center bg-white flex-shrink-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_plus = _resolveComponent("icon-plus")!
  const _component_icon_check = _resolveComponent("icon-check")!
  const _component_loading_animation = _resolveComponent("loading-animation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.chosenCustomers.length != 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, [
            _createTextVNode(_toDisplayString(_ctx.$t("chosen_customers")) + " ", 1),
            _createElementVNode("a", {
              class: _normalizeClass(["text-sm transition-all", `${
                        _ctx.chosenCustomers.length == _ctx.maxDisplayedCustomers
                            ? 'text-grey'
                            : 'text-grey'
                    }`])
            }, "(" + _toDisplayString(_ctx.chosenCustomers.length) + "/" + _toDisplayString(_ctx.maxDisplayedCustomers) + ")", 3)
          ]),
          _createElementVNode("div", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chosenCustomers, (customer, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "w-full group hover:-translate-x-2 transform h-9 bg-white hover:bg-white-dark transition-all cursor-pointer rounded-md mb-1 p-2 flex items-center border-2 border-primary hover:border-red",
                onClick: ($event: any) => (_ctx.removeCustomer(index))
              }, [
                _createVNode(_component_icon_plus, {
                  color: 'red',
                  class: "transform rotate-45 fixed group-hover:opacity-100 opacity-0 transition-all"
                }),
                _createVNode(_component_icon_check, {
                  color: 'primary',
                  class: "group-hover:opacity-0 opacity-100 transition-all"
                }),
                _createElementVNode("p", _hoisted_5, _toDisplayString(customer.name), 1)
              ], 8, _hoisted_4))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("p", _hoisted_6, [
      _createTextVNode(_toDisplayString(_ctx.$t("search_result")) + " ", 1),
      _createElementVNode("a", _hoisted_7, "(" + _toDisplayString(_ctx.filteredCustomers.length) + "/" + _toDisplayString(_ctx.maxCustomers) + ")", 1)
    ]),
    (!_ctx.isLoading && _ctx.filteredCustomers.length == 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t("no_search_result")), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_9, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredCustomers, (customer, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass(["w-full h-9 bg-white-dark hover:bg-grey-lightest transition-all rounded-md mb-1 p-2 flex items-center", `${
                    _ctx.chosenCustomers.length == 6
                        ? 'cursor-not-allowed'
                        : 'cursor-pointer'
                }`]),
          onClick: ($event: any) => (_ctx.addCustomer(customer))
        }, [
          _createVNode(_component_icon_plus),
          _createElementVNode("p", _hoisted_11, _toDisplayString(customer.name), 1)
        ], 10, _hoisted_10))
      }), 128))
    ]),
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _createVNode(_component_loading_animation, {
            id: 'loadingAnimation',
            name: 'loadingAnimation',
            class: "mt-2",
            animationStyle: {
                    radius: '12',
                    animationColor: 'grey',
                }
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}