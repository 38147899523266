
import { defineComponent, ref } from "vue";
import { storeToRefs } from "pinia";
import { useNormStore } from "@/store/norms";
import { useCustomerNormStore } from "@/store/customerNormRelation";
import { CustomerNormRelationItemInterface } from "@/store/customerNormRelation/interfaces";
import ListItemCheckBox from "./ListItemCheckBox.vue";
import IconArrow from "@/components/icons/IconArrowBottom.vue";
import { computed } from "@vue/reactivity";
export default defineComponent({
    name: "ListItemContainer",
    props: {
        item: {
            type: Object as () => CustomerNormRelationItemInterface,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
    },
    emits: ["openDetails"],
    components: { ListItemCheckBox, IconArrow },
    setup() {
        const { norm } = storeToRefs(useNormStore());
        const { chosenCustomers } = storeToRefs(useCustomerNormStore());
        const isOpen = ref(false);

        const listItemCheckboxColCount = computed(() => {
            let maxWidth = "grid-cols-4";
            if (chosenCustomers.value.length == 5) {
                maxWidth = "grid-cols-5";
            }
            if (chosenCustomers.value.length == 6) {
                maxWidth = "grid-cols-6";
            }
            return maxWidth;
        });

        return { norm, chosenCustomers, listItemCheckboxColCount, isOpen };
    },
});
