import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "flex gap-2 items-center" }
const _hoisted_2 = { class: "w-6 flex-shrink-0" }
const _hoisted_3 = ["src", "title"]
const _hoisted_4 = { class: "text-black-light text-sm w-48 truncate" }
const _hoisted_5 = { class: "flex gap-2 flex-shrink-0" }
const _hoisted_6 = { class: "text-black-light text-sm" }
const _hoisted_7 = { class: "flex gap-2 flex-shrink-0" }
const _hoisted_8 = { class: "text-grey-dark text-sm" }
const _hoisted_9 = { class: "text-black-light text-sm" }
const _hoisted_10 = { class: "flex gap-2 flex-shrink-0" }
const _hoisted_11 = { class: "text-grey-dark text-sm" }
const _hoisted_12 = { class: "text-black-light text-sm" }
const _hoisted_13 = { class: "flex gap-2 flex-shrink-0" }
const _hoisted_14 = { class: "text-grey-dark text-sm" }
const _hoisted_15 = { class: "text-black-light text-sm" }
const _hoisted_16 = { class: "text-primary-darkest text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_arrow = _resolveComponent("icon-arrow")!
  const _component_list_item_check_box = _resolveComponent("list-item-check-box")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["md:hidden p-2 gap-2 flex flex-col bg-white-dark hover:bg-grey-lightest flex-shrink-0 rounded-md cursor-pointer overflow-hidden", `${
            _ctx.isOpen ? 'max-h-216' : 'max-h-10'
        } border-l-2 transform transition-all ${
            _ctx.norm.id == _ctx.item.id
                ? 'border-primary bg-grey-lightest hover:bg-grey-lighter'
                : 'border-transparent'
        }`]),
    onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.isOpen = !_ctx.isOpen), ["stop"]))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex border-b w-full pb-2 justify-between items-center", `${_ctx.isOpen ? 'border-grey-lighter' : 'border-transparent'}`])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: _ctx.item.state_icon_url,
            alt: "status",
            title: _ctx.item.state_label
          }, null, 8, _hoisted_3)
        ]),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.item.type_short_name) + " - " + _toDisplayString(_ctx.item.number), 1)
      ]),
      _createVNode(_component_icon_arrow, {
        class: _normalizeClass(`${
                    _ctx.isOpen ? 'rotate-180' : ''
                } transform transition-all flex-shrink-0`)
      }, null, 8, ["class"])
    ], 2),
    _createElementVNode("div", _hoisted_5, [
      _cache[2] || (_cache[2] = _createElementVNode("p", { class: "text-grey-dark text-sm" }, "#", -1)),
      _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.item.index), 1)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t("state")), 1),
      _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.item.status_short_name), 1)
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t("type")), 1),
      _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.item.type_short_name), 1)
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.$t("number")), 1),
      _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.item.number), 1)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chosenCustomers, (customer) => {
      return (_openBlock(), _createBlock(_component_list_item_check_box, {
        key: customer.id,
        item: _ctx.item,
        label: customer.name,
        customer: customer,
        index: _ctx.index
      }, null, 8, ["item", "label", "customer", "index"]))
    }), 128)),
    _createElementVNode("div", {
      class: "w-full border-t border-grey-lighter flex items-center justify-center pt-2 mt-2",
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (
                _ctx.$router.push({
                    name: 'CustomerNormRelationDetails',
                    params: { id: _ctx.item.id },
                }),
                    _ctx.$emit('openDetails')
            ), ["stop"]))
    }, [
      _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.$t("open_details")), 1)
    ])
  ], 2))
}