import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center md:justify-between text-center flex-shrink-0" }
const _hoisted_2 = { class: "md:h-6 md:w-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_checkbox = _resolveComponent("input-checkbox")!
  const _component_loading_animation = _resolveComponent("loading-animation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_input_checkbox, {
            key: 0,
            "model-value": _ctx.checkIfHasNorm(_ctx.customer),
            id: "checkbox",
            name: "checkbox",
            label: _ctx.label,
            hasMeta: false,
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (
                    !_ctx.isSettingCustomerNorm
                        ? _ctx.changeCustomerNormStatus(_ctx.customer)
                        : ''
                ), ["stop"]))
          }, null, 8, ["model-value", "label"]))
        : (_openBlock(), _createBlock(_component_loading_animation, {
            key: 1,
            id: 'loadingAnimation',
            name: 'loadingAnimation',
            class: "-ml-1.5",
            animationStyle: {
                    radius: '12',
                    animationColor: 'grey',
                }
          }))
    ])
  ]))
}