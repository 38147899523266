
import { defineComponent } from "vue";
import { storeToRefs } from "pinia";
import { useNormStore } from "@/store/norms";
import { useCustomerNormStore } from "@/store/customerNormRelation";
import { CustomerNormRelationItemInterface } from "@/store/customerNormRelation/interfaces";
import ListItemCheckBox from "./ListItemCheckBox.vue";
import { computed } from "@vue/reactivity";
export default defineComponent({
    name: "ListItemContainer",
    props: {
        item: {
            type: Object as () => CustomerNormRelationItemInterface,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
    },
    emits: ["openDetails"],
    components: { ListItemCheckBox },
    setup() {
        const { norm } = storeToRefs(useNormStore());
        const { chosenCustomers } = storeToRefs(useCustomerNormStore());

        const customerHeaderColCount = computed(() => {
            return `grid-template-columns: repeat(${chosenCustomers.value.length}, minmax(0, 1fr));`;
        });

        return { norm, chosenCustomers, customerHeaderColCount };
    },
});
