import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full h-full overflow-hidden flex flex-col" }
const _hoisted_2 = { class: "w-full h-36 bg-white-dark pr-3 pl-3 left-0 pt-5 absolute top-0 flex-shrink-0" }
const _hoisted_3 = { class: "flex w-full items-center justify-between" }
const _hoisted_4 = { class: "w-full px-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_close = _resolveComponent("icon-close")!
  const _component_icon_container = _resolveComponent("icon-container")!
  const _component_input_search = _resolveComponent("input-search")!
  const _component_customer_overview = _resolveComponent("customer-overview")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex-shrink-0 absolute lg:relative top-0 right-0 h-full bg-white p-3 pt-0 pl-5 transform transition-all duration-300 overflow-hidden", 
            _ctx.isContainerOpen
                ? 'w-full md:w-1/2 lg:w-2/5 shadow-dark'
                : 'w-0 -mr-8'
        ])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h6", null, _toDisplayString(_ctx.$t("customer_overview")), 1),
          _createVNode(_component_icon_container, {
            id: 'closeIcon',
            class: "ml-2",
            containerStyle: { backgroundColor: 'white-dark' },
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_icon_close)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_input_search, {
            class: "w-full h-10 mt-5",
            id: 'id',
            name: 'textFilter',
            modelValue: _ctx.customerParams.search,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.customerParams.search) = $event)),
            inputStyle: { backgroundColor: 'white' },
            placeholder: _ctx.$t('customer')
          }, null, 8, ["modelValue", "placeholder"])
        ])
      ]),
      _createVNode(_component_customer_overview, { class: "mt-36" })
    ])
  ], 2))
}