import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "h-full w-full flex flex-col overflow-hidden relative pt-3" }
const _hoisted_2 = {
  id: "filterSection",
  class: "flex justify-between w-full h-10"
}
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { class: "flex w-full justify-end items-center mr-3" }
const _hoisted_5 = { class: "text-sm text-primary" }
const _hoisted_6 = { class: "flex" }
const _hoisted_7 = { class: "text-sm mb-2" }
const _hoisted_8 = { class: "grid md:hidden grid-cols-2 gap-2 flex-row justify-start mr-2" }
const _hoisted_9 = { class: "hidden md:flex gap-2 flex-row justify-start mr-2" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "transform -rotate-90" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_search = _resolveComponent("input-search")!
  const _component_icon_close = _resolveComponent("icon-close")!
  const _component_icon_filter = _resolveComponent("icon-filter")!
  const _component_icon_container = _resolveComponent("icon-container")!
  const _component_input_select = _resolveComponent("input-select")!
  const _component_filter_item = _resolveComponent("filter-item")!
  const _component_drop_down = _resolveComponent("drop-down")!
  const _component_item_list = _resolveComponent("item-list")!
  const _component_add_norm_modal = _resolveComponent("add-norm-modal")!
  const _component_side_container_customer = _resolveComponent("side-container-customer")!
  const _component_side_container_norm = _resolveComponent("side-container-norm")!
  const _component_main_container = _resolveComponent("main-container")!

  return (_openBlock(), _createBlock(_component_main_container, {
    id: 'norm_overview',
    name: 'norm_overview',
    title: _ctx.$t('customer_norm_relation')
  }, {
    append: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["w-10 h-full absolute transition-all duration-300", `${
                    _ctx.isSideContainerOpen ? 'lg:right-2/5 right-1/2' : 'right-0'
                }`])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: _normalizeClass(["w-full h-24 flex items-center justify-center rounded-l-xl cursor-pointer border-2 border-r-0 border-grey-lighter transition-all", `${
                        _ctx.chosenMenuItem == item.name
                            ? 'bg-white'
                            : 'bg-white-dark ml-1 hover:-translate-x-1 transform'
                    } ${item.name == 'norm' && !_ctx.norm.id ? 'hidden' : ''}`]),
            onClick: ($event: any) => (_ctx.openSideContainer(item.name))
          }, [
            _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t(item.name)), 1)
          ], 10, _hoisted_10))
        }), 128))
      ], 2),
      (_ctx.chosenMenuItem == 'customer')
        ? (_openBlock(), _createBlock(_component_side_container_customer, {
            key: 0,
            isContainerOpen: _ctx.isSideContainerOpen,
            onEditNorm: _cache[6] || (_cache[6] = ($event: any) => (_ctx.openEditNorm = true)),
            onClose: _cache[7] || (_cache[7] = ($event: any) => (_ctx.isSideContainerOpen = false))
          }, null, 8, ["isContainerOpen"]))
        : (_openBlock(), _createBlock(_component_side_container_norm, {
            key: 1,
            open: _ctx.isSideContainerOpen,
            onEditNorm: _cache[8] || (_cache[8] = ($event: any) => (_ctx.openEditNorm = true)),
            onClose: _cache[9] || (_cache[9] = ($event: any) => (_ctx.isSideContainerOpen = false))
          }, null, 8, ["open"]))
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_input_search, {
            class: "w-64 h-10 flex-shrink-0",
            id: 'id',
            name: 'textFilter',
            modelValue: _ctx.params.search,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.params.search) = $event)),
            placeholder: 'Nummern, Titel'
          }, null, 8, ["modelValue"]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              (
                                _ctx.params.search ||
                                _ctx.params.policy_ids.length > 0 ||
                                _ctx.params.type_ids.length > 0
                            )
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "flex items-center cursor-pointer",
                    onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.clearAllFilters()), ["stop"]))
                  }, [
                    _createVNode(_component_icon_close, {
                      color: 'primary',
                      class: "transform scale-90 mt-px"
                    }),
                    _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("clear_all_filter")), 1)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_drop_down, {
                id: 'filterDropdown',
                ref: "dropdownElement",
                dropdownStyle: { widthClasses: 'w-64' }
              }, {
                trigger: _withCtx(() => [
                  _createVNode(_component_icon_container, { id: 'navbar-search-icon' }, {
                    default: _withCtx(() => [
                      _createVNode(_component_icon_filter, {
                        color: 
                                            _ctx.combinedFilter.length != 0
                                                ? 'primary'
                                                : 'grey-dark'
                                        
                      }, null, 8, ["color"])
                    ]),
                    _: 1
                  })
                ]),
                content: _withCtx(() => [
                  _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("filter")), 1),
                  _createVNode(_component_input_select, {
                    id: "selectInputType",
                    name: "selectInputType",
                    label: `${_ctx.$t('type')} (${
                                        _ctx.params?.type_ids?.length
                                    }/3)`,
                    isMultiSelect: true,
                    options: _ctx.filteredTypes,
                    selectItemConfiguration: {
                                        valueColumn: 'id',
                                        labelColumn: 'name',
                                    },
                    useValueColumnAsValue: true,
                    isInputDisabled: true,
                    hasSearch: true,
                    searchValue: _ctx.typesTextFilter,
                    modelValue: _ctx.params.type_ids,
                    "onUpdate:modelValue": [
                      _cache[2] || (_cache[2] = ($event: any) => ((_ctx.params.type_ids) = $event)),
                      _ctx.checkTypeFilterLength
                    ],
                    "onUpdate:searchValue": _ctx.updateSearchValue,
                    "onChange:searchValue": _ctx.updateSearchValue
                  }, null, 8, ["label", "options", "searchValue", "modelValue", "onUpdate:modelValue", "onUpdate:searchValue", "onChange:searchValue"]),
                  _createTextVNode(" " + _toDisplayString(_ctx.typesTextFilter) + " ", 1),
                  _createElementVNode("div", _hoisted_8, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.combinedFilter, (filterItem) => {
                      return (_openBlock(), _createBlock(_component_filter_item, {
                        key: filterItem,
                        filter: filterItem,
                        onRemoveFilter: ($event: any) => (
                                            _ctx.removeFilterItem(filterItem)
                                        )
                      }, null, 8, ["filter", "onRemoveFilter"]))
                    }), 128))
                  ])
                ]),
                _: 1
              }, 512)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.combinedFilter, (filterItem) => {
            return (_openBlock(), _createBlock(_component_filter_item, {
              key: filterItem.index,
              filter: filterItem,
              onRemoveFilter: ($event: any) => (_ctx.removeFilterItem(filterItem))
            }, null, 8, ["filter", "onRemoveFilter"]))
          }), 128))
        ]),
        _createVNode(_component_item_list, {
          onOpenDetails: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isSideContainerOpen = true))
        })
      ]),
      (_ctx.openEditNorm)
        ? (_openBlock(), _createBlock(_component_add_norm_modal, {
            key: 0,
            onSuccess: _cache[4] || (_cache[4] = ($event: any) => (_ctx.editNormSuccess())),
            onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.openEditNorm = false))
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title"]))
}