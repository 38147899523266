
import { defineComponent } from "vue";

export default defineComponent({
  name: "IconPlus2",
  props: {
    /**
     * Is the main stroke color of the icon. It should be a tailwind class without the theme prefix ('fill-').
     */
    color: {
      type: String,
      default: "grey-darker",
    },
  },
});
