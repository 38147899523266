
import MainContainer from "@/components/layout/mainContainer/MainContainer.vue";
import IconFilter from "@/components/icons/IconFilter.vue";
import IconClose from "@/components/icons/IconClose.vue";
import InputSearch from "@/components/inputs/inputSearch/InputSearch.vue";
import InputSelect from "@/components/inputs/inputSelect/InputSelect.vue";
import DropDown from "@/components/helpers/dropDown/DropDown.vue";
import FilterItem from "../normAdministration/containers/FilterItemContainer.vue";
import ItemList from "./containers/ItemList.vue";
import SideContainerCustomer from "./containers/SideContainer.vue";
import SideContainerNorm from "../normAdministration/containers/SideContainer.vue";
import IconContainer from "@/components/helpers/iconContainer/IconContainer.vue";
import AddNormModal from "../normAdministration/modals/AddNormModal.vue";

import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useNormStore } from "@/store/norms";
import { useCustomerNormStore } from "@/store/customerNormRelation";
import { useBaseDataStore } from "@/store/settings/baseDataStore";
import { useHelpersStore } from "@/store/helpers";
import { NormListItemInterface } from "@/store/norms/interfaces";
import { useRoute } from "vue-router";
import { useCustomerStore } from "@/store/customer";

export default defineComponent({
    name: "customerNormAdministration",
    components: {
        MainContainer,
        IconFilter,
        InputSearch,
        DropDown,
        IconContainer,
        InputSelect,
        FilterItem,
        SideContainerCustomer,
        ItemList,
        AddNormModal,
        SideContainerNorm,
        IconClose,
    },
    setup() {
        const { norm, customerPagination, normList } = storeToRefs(
            useNormStore()
        );
        const { params } = storeToRefs(useCustomerNormStore());
        const { customerList } = storeToRefs(useCustomerStore());
        const { types, policies } = storeToRefs(useBaseDataStore());
        const { fetchNormData, fetchNormCustomers } = useNormStore();
        const { setLoadingId, removeLoadingId } = useHelpersStore();
        const { fetchBaseDataPolicies, fetchBaseDataTypes } =
            useBaseDataStore();

        const typesTextFilter = ref("");
        const openEditNorm = ref(false);
        const openDetails = ref(false);
        const isSideContainerOpen = ref(true);
        const chosenMenuItem = ref("customer");
        const route = useRoute();
        const menuItems = ref([
            {
                name: "customer",
            },
            {
                name: "norm",
            },
        ]);

        watch(
            () => route.path,
            () => {
                const id = route.params.id?.toString();
                const routeName = route.name?.toString();
                if (
                    routeName == "CustomerNormRelation" ||
                    routeName == "CustomerNormRelationDetails"
                ) {
                    if (!id) {
                        norm.value = {
                            notify_customers: false,
                            notify_users: false,
                        };
                    } else {
                        openNormDetails({ id: id });
                    }
                }
            }
        );

        function clearAllFilters() {
            params.value.policy_ids = [];
            params.value.type_ids = [];
            params.value.search = "";
        }

        const combinedFilter = computed(() => {
            let filters: any[] = [];
            params.value.policy_ids?.forEach((policy_id, index: number) => {
                const policy = policies.value.find(
                    (policy) => policy.id == policy_id
                );
                filters.push({ value: policy, type: "policy", index: index });
            });
            params.value.type_ids?.forEach((type_id, index: number) => {
                const type = types.value.find((type) => type.id == type_id);
                filters.push({ value: type, type: "type", index: index });
            });
            return filters;
        });

        const filteredTypes = computed(() => {
            return types.value.filter((type) =>
                type.name
                    ?.toLowerCase()
                    .includes(typesTextFilter.value.toLowerCase())
            );
        });

        function removeFilterItem(item: any): void {
            if (item.type == "policy") {
                params.value.policy_ids?.splice(item.index, 1);
            } else {
                params.value.type_ids?.splice(item.index, 1);
            }
        }

        function openSideContainer(itemName: string): void {
            chosenMenuItem.value = itemName;
            setTimeout(() => {
                isSideContainerOpen.value = true;
            }, 10);
        }

        function checkPolicyFilterLength(): void {
            const policyFilterLength = params.value.policy_ids?.length;
            if (policyFilterLength && policyFilterLength > 3) {
                params.value.policy_ids?.pop();
            }
        }

        function updateSearchValue(value: string) {
            typesTextFilter.value = value;
        }

        function checkTypeFilterLength(): void {
            const typeFilterLength = params.value.type_ids?.length;
            if (typeFilterLength && typeFilterLength > 3) {
                params.value.type_ids?.pop();
            }
        }

        async function openNormDetails(normId: NormListItemInterface) {
            setLoadingId("norm_details");
            openDetails.value = true;
            chosenMenuItem.value = "norm";
            customerPagination.value.page = 1;
            if (normId.id) {
                const response = await fetchNormData(normId.id);
                if (response?.status == 200) {
                    await fetchNormCustomers(normId.id);
                    customerPagination.value.page++;
                }
            }
            removeLoadingId("norm_details");
        }

        function editNormSuccess(): void {
            openEditNorm.value = false;
        }
        onMounted(() => {
            normList.value = [];
            customerList.value = [];
            let routeId;
            if (route.params.id) {
                routeId = route.params.id.toString();
            }
            if (routeId) {
                openNormDetails({ id: routeId });
            } else {
                norm.value = {
                    notify_customers: false,
                    notify_users: false,
                };
            }

            fetchBaseDataPolicies();
            fetchBaseDataTypes();
        });

        return {
            openNormDetails,
            params,
            removeFilterItem,
            combinedFilter,
            editNormSuccess,
            norm,
            menuItems,
            chosenMenuItem,
            isSideContainerOpen,
            types,
            policies,
            openEditNorm,
            checkPolicyFilterLength,
            checkTypeFilterLength,
            openSideContainer,
            filteredTypes,
            typesTextFilter,
            updateSearchValue,
            clearAllFilters,
        };
    },
});
