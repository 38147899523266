
import { computed, defineComponent } from "vue";
import InputCheckbox from "@/components/inputs/inputCheckbox/InputCheckbox.vue";
import { storeToRefs } from "pinia";
import { useCustomerNormStore } from "@/store/customerNormRelation";
import { CustomerListItemInterface } from "@/store/customer/interfaces";
import { CustomerNormRelationItemInterface } from "@/store/customerNormRelation/interfaces";
import LoadingAnimation from "@/components/helpers/loadingAnimation/LoadingAnimation.vue";
import { useHelpersStore } from "@/store/helpers";
export default defineComponent({
    name: "ListItemContainer",
    props: {
        item: {
            type: Object as () => CustomerNormRelationItemInterface,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        label: {
            type: String,
            default: "",
        },
        customer: {
            type: Object as () => CustomerListItemInterface,
            required: true,
        },
    },
    emits: ["openDetails"],
    components: { InputCheckbox, LoadingAnimation },
    setup(props) {
        const { normList } = storeToRefs(useCustomerNormStore());
        const { updateCustomerNorm } = useCustomerNormStore();
        const { setLoadingId, removeLoadingId } = useHelpersStore();
        const { loadingIds } = storeToRefs(useHelpersStore());

        const isLoading = computed(() => {
            return loadingIds.value.some(
                (id) =>
                    id.id ===
                    "check-box-loading-" +
                        props.customer.id +
                        "-" +
                        props.item.id
            );
        });

        const isSettingCustomerNorm = computed(() => {
            return loadingIds.value.some((id) =>
                id.id?.toString().includes("check-box-loading-")
            );
        });

        function checkIfHasNorm(customer: CustomerListItemInterface): boolean {
            return (
                props.item.customer_ids?.some(
                    (id: number) => id == customer.id
                ) ?? false
            );
        }

        async function changeCustomerNormStatus(
            customer: CustomerListItemInterface
        ): Promise<void> {
            if (!isLoading.value) {
                setLoadingId(
                    "check-box-loading-" + customer.id + "-" + props.item.id
                );
                const response = await updateCustomerNorm(
                    props.item.id,
                    customer.id as number
                );
                removeLoadingId(
                    "check-box-loading-" + customer.id + "-" + props.item.id
                );
                if (response?.status == 200) {
                    const customerWasAttached =
                        response.data.data?.attached?.includes(customer.id);
                    const customerWasDetached =
                        response.data.data?.detached?.includes(customer.id);
                    if (customerWasAttached) {
                        normList.value[props.index].customer_ids?.push(
                            customer.id as number
                        );
                    } else if (customerWasDetached) {
                        const customerIndex = normList.value[
                            props.index
                        ].customer_ids?.indexOf(customer.id as number);
                        if (
                            customerIndex !== undefined &&
                            customerIndex !== -1
                        ) {
                            normList.value[props.index].customer_ids?.splice(
                                customerIndex,
                                1
                            );
                        }
                    }
                }
            }
        }

        return {
            checkIfHasNorm,
            changeCustomerNormStatus,
            isLoading,
            isSettingCustomerNorm,
        };
    },
});
