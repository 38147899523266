import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "Icon",
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  viewBox: "0 0 20 20"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      id: "outline2",
      d: "M5.414,4,7.707,1.707A1,1,0,0,0,6.293.293L4,2.586,1.707.293A1,1,0,0,0,.293,1.707L2.586,4,.293,6.293A1,1,0,1,0,1.707,7.707L4,5.414,6.293,7.707A1,1,0,0,0,7.707,6.293Z",
      transform: "translate(10 4.343) rotate(45)",
      class: _normalizeClass('fill-' + _ctx.color)
    }, null, 2),
    _createElementVNode("path", {
      id: "outline1",
      d: "M1023,95a8,8,0,1,1-8,8,8.009,8.009,0,0,1,8-8m0-2a10,10,0,1,0,10,10,10,10,0,0,0-10-10Z",
      transform: "translate(-1013 -93)",
      class: _normalizeClass('fill-' + _ctx.color)
    }, null, 2)
  ]))
}