import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "ml-2 w-6 flex-shrink-0 mr-4" }
const _hoisted_2 = ["src", "title"]
const _hoisted_3 = { class: "w-9 flex-shrink-0" }
const _hoisted_4 = { class: "text-grey-darker truncate text-sm" }
const _hoisted_5 = { class: "w-16 flex-shrink-0" }
const _hoisted_6 = { class: "text-grey-darker truncate text-sm" }
const _hoisted_7 = { class: "flex-shrink-0 w-28" }
const _hoisted_8 = { class: "text-grey-darker truncate text-sm" }
const _hoisted_9 = { class: "text-grey-darker truncate text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_list_item_check_box = _resolveComponent("list-item-check-box")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["items-center flex justify-start h-9 bg-white-dark hover:bg-grey-lightest flex-shrink-0 rounded-md cursor-pointer border-l-2", `${
            _ctx.norm.id == _ctx.item.id
                ? 'border-primary bg-grey-lightest hover:bg-grey-lighter'
                : 'border-transparent'
        }`]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (
            _ctx.$router.push({
                name: 'CustomerNormRelationDetails',
                params: { id: _ctx.item.id },
            })
        ))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        src: _ctx.item.state_icon_url,
        alt: "status",
        title: _ctx.item.state_label
      }, null, 8, _hoisted_2)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.item.index), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.item.status_short_name), 1)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.item.type_short_name), 1)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["flex-shrink-0", `${_ctx.chosenCustomers.length < 4 ? 'w-44' : 'w-28'}`])
    }, [
      _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.item.number), 1)
    ], 2),
    _createElementVNode("div", {
      class: "grid gap-3 w-full pl-3 pr-1",
      style: _normalizeStyle(_ctx.customerHeaderColCount)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chosenCustomers, (customer) => {
        return (_openBlock(), _createBlock(_component_list_item_check_box, {
          key: customer.id,
          item: _ctx.item,
          customer: customer,
          index: _ctx.index
        }, null, 8, ["item", "customer", "index"]))
      }), 128))
    ], 4)
  ], 2))
}